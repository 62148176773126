import Vue from 'vue'

const getDefaultState = () => {
  return {
    teamList: [],
    isLoading: false,
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    async getTeamData({ commit, state }, userId) {
      commit('SET_LOADER', true)
      try {
        const response = await Vue.prototype.$http.get(
          `/dmp/dashboard/team?userId=${userId}`
        )

        if (response && response.data && response.data.status === 200) {
          if (response.data.data) {
            commit('SET_TEAM_LIST', response.data.data)
          }
        }
      } catch (error) {
        throw error
      } finally {
        commit('SET_LOADER', false)
      }
    },

    async updateTeamData({ commit }, data) {
      commit('SET_LOADER', true)

      try {
        const { userId, payload } = data
        const response = await Vue.prototype.$http.put(
          `dmp/user-management/editteam?userId=${userId}`,
          payload
        )

        // Check if the response exists and has data
        if (response && response.data) {
          return response?.data
        }
      } catch (error) {
        throw new Error(
          error.response?.data?.info ||
            'An error occurred while updating the team.'
        )
      } finally {
        commit('SET_LOADER', false)
      }
    }
  },
  mutations: {
    SET_LOADER(state, isLoading) {
      state.isLoading = isLoading
    },
    SET_TEAM_LIST(state, teamList) {
      state.teamList = teamList
    }
  },
  getters: {
    getTeamList: (state) => state.teamList
  }
}
