import Vue from 'vue'
import store from '../index'
import { workTypePriority, entityPoolPriority } from '../../util/util'
import moment from 'moment'

const getDefaultState = () => {
  return {
    asignedEntityDetails: [],
    workType: []
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    getWorkType({ commit }) {
      Vue.prototype.$http
        .get('dmp/work-type/')
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              commit('SET_WORK_TYPE', response.data.data)
            }
          }
          if (response.data.status === 204) {
            //  todo: action to be decided
          }
        })
        .catch((e) => {
          // commit('LOADING', false)
        })
    },
    getAssignedEntityDetails({ commit }, userId) {
      Vue.prototype.$http
        .get(`dmp/assigned-entities?userId=${userId}`)
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              response.data.data.forEach((entity) => {
                if (entity.assignedDate) {
                  const initialDateString = entity.assignedDate
                  const initialDate = moment.utc(initialDateString).local()
                  switch (entity.priority) {
                    case entityPoolPriority.HIGH:
                      initialDate.add(4, 'hours')
                      break
                    case entityPoolPriority.MEDIUM:
                      initialDate.add(8, 'hours')
                      break
                    case entityPoolPriority.LOW:
                      initialDate.add(24, 'hours')
                      break
                    // Handle other cases if needed
                  }
                  entity.TAT = initialDate
                }
              })
              commit('SET_ENTITY_DETAILS', response.data.data)
            }
          }
        })
    }
  },
  mutations: {
    SET_WORK_TYPE(state, workType) {
      state.entityTypeList = workType.map((workType) => {
        workType.id = workType.name
        return workType
      })

      state.workType = workType
    },
    SET_ENTITY_DETAILS(state, entityDetils) {
      state.asignedEntityDetails = []
      entityDetils.forEach((entity) => {
        const entityObj = {}
        entityObj.sgaId = entity.sgaId
        entityObj.partyId = entity.partyId
        entityObj.requestId = entity.requestId
        entityObj.legalEntityName = entity.legalEntityName
        entityObj.countryName = entity.countryName
        entityObj.TAT = entity.priority ? entity.TAT : null
        entityObj.tatValue = entity.calculatedDate
        entityObj.ultimateParentRequestId = entity.ultimateParentRequestId
        entityObj.ultimateParentName = entity.ultimateParentName
        entityObj.assignedTo = entity.firstName + ' ' + entity.lastName
        entityObj.status = entity.status
        entityObj.workType = entity.workType
        entityObj.assignmentId = entity.assignmentId
        entityObj.priority = entity.priority
        entityObj.isUpdated = entity.isUpdated

        state.asignedEntityDetails.push(entityObj)
      })

      state.asignedEntityDetails.sort((a, b) => {
        const priorityA = a.workType.map((type) => workTypePriority[type])
        const priorityB = b.workType.map((type) => workTypePriority[type])

        // Find the minimum priority for each entry
        const minPriorityA = Math.min(...priorityA)
        const minPriorityB = Math.min(...priorityB)

        // Sort based on the minimum priority
        if (minPriorityA < minPriorityB) {
          return -1
        } else if (minPriorityA > minPriorityB) {
          return 1
        } else {
          // If minimum priorities are the same, sort by assigned date or other criteria
          return 0
        }
      })
    }
  }
}
