import Vue from 'vue'

const getDefaultState = () => {
  return {
    details2052a: null,
    enrichmentAnswerChoices: [],
    gsibNameList: [],
    isSwapDealersMandatory: false,
    isLoading: false
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    async get2052aDetails({ commit }, sgaId) {
      commit('SET_LOADING', true)
      try {
        const response = await Vue.prototype.$http.get(
          `/dmp/2052a?sgaId=${sgaId}`
        )
        if (
          response.data &&
          response.data.status === 200 &&
          response.data.data
        ) {
          commit('SET_2052A_DETAILS', response.data.data)
        } else {
          commit('RESET_2052A_DETAILS')
        }
      } catch (error) {
        throw error
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async update2052aDetails({ commit, dispatch }, payload) {
      commit('SET_LOADING', true)
      const { sgaId, payloadData } = payload
      try {
        const response = await Vue.prototype.$http.put(
          `/dmp/2052a?sgaId=${sgaId}`,
          payloadData
        )
        if (
          response.data &&
          response.data.status === 200 &&
          response.data.data
        ) {
          commit('SET_2052A_DETAILS', response.data.data)
          return response.data.status
        }
      } catch (error) {
        throw error
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async getEnrichmentAnswerChoices({ commit }) {
      commit('SET_LOADING', true)
      try {
        const response = await Vue.prototype.$http.get(
          '/dmp/common/enrichment-answer-choices'
        )
        if (
          response.data &&
          response.data.status === 200 &&
          response.data.data
        ) {
          commit('SET_ENRICHMENT_ANSWER_CHOICES', response.data.data)
        }
      } catch (error) {
        throw error
      } finally {
        commit('SET_LOADING', false)
      }
    },
    reset2052Details({ commit }) {
      commit('RESET_2052A_DETAILS')
    },
    async getGsibName({ commit }) {
      commit('SET_LOADING', true)
      try {
        const response = await Vue.prototype.$http.get(`/dmp/2052a/gsibname`)
        if (
          response.data &&
          response.data.status === 200 &&
          response.data.data
        ) {
          commit('SET_GSIB_Name', response.data.data)
        }
      } catch (error) {
        throw error
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async getSwapDealers({ commit, state, rootState }, sgaId) {
      commit('SET_LOADING', true)
      try {
        const response = await Vue.prototype.$http.get(`/dmp/2052a/swapDealers`)
        if (
          response.data &&
          response.data.status === 200 &&
          response.data.data
        ) {
          const swapDealers = response.data.data
          if (swapDealers.length) {
            const { basicDetails } = rootState['basicDetails'] // For accessing namespaced module
            commit('SET_SWAP_DEALERS_FLAG', { swapDealers, basicDetails })
          }
        }
      } catch (error) {
        throw error
      } finally {
        commit('SET_LOADING', false)
      }
    }
  },
  mutations: {
    SET_2052A_DETAILS(state, details2052a) {
      state.details2052a = { ...details2052a }
    },

    RESET_2052A_DETAILS(state) {
      state.details2052a = null
    },
    SET_ENRICHMENT_ANSWER_CHOICES(state, enrichmentAnswerChoices) {
      state.enrichmentAnswerChoices = enrichmentAnswerChoices.map(
        (entityTypeObj) => {
          // Assign new key

          entityTypeObj.value = entityTypeObj.id
          entityTypeObj.text = `${entityTypeObj.id}-${entityTypeObj.name}`

          // Delete old key
          delete entityTypeObj.id
          delete entityTypeObj.name

          return entityTypeObj
        }
      )
    },
    SET_GSIB_Name(state, data) {
      state.gsibNameList = data.map((option) => {
        const gsibNameListObj = {}
        gsibNameListObj.value = option.id
        gsibNameListObj.text = option.name
        return gsibNameListObj
      })
    },
    SET_LOADING(state, isLoading) {
      state.isLoading = isLoading
    },
    SET_SWAP_DEALERS_FLAG(state, { swapDealers, basicDetails }) {
      // Utility function to normalize entity names and alias by removing special characters and extra spaces
      const normalizeEntityName = (name) => {
        if (!name) return ''
        return name
          .replace(/[.,\n\t]/g, ' ') // Replace special characters with space
          .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
          .trim() // Trim leading and trailing spaces
      }
      // Convert swapDealers to lowercase for matching
      const swapDealerPrefixes = new Set(
        swapDealers.map((dealer) =>
          normalizeEntityName(dealer.entityName).toLowerCase()
        )
      )

      // Normalize legalEntityName, previousEntityName and alias to compare with swap dealer prefixes
      const entityNamePrefix = basicDetails.legalEntityName
        ? normalizeEntityName(basicDetails.legalEntityName).toLowerCase()
        : ''

      // Split previousEntityName and alias by '|' and normalize each part
      const previousEntityNamePrefixes = basicDetails.previousEntityName
        ? basicDetails.previousEntityName
            .split('|')
            .map((name) => normalizeEntityName(name).toLowerCase())
        : []

      const aliasPrefixes = basicDetails.alias
        ? basicDetails.alias
            .split('|')
            .map((alias) => normalizeEntityName(alias).toLowerCase())
        : []

      // Set isSwapDealersMandatory to true if any of the prefixes match
      if (
        swapDealerPrefixes.has(entityNamePrefix) ||
        previousEntityNamePrefixes.some((prefix) =>
          swapDealerPrefixes.has(prefix)
        ) ||
        aliasPrefixes.some((prefix) => swapDealerPrefixes.has(prefix))
      ) {
        state.isSwapDealersMandatory = true
      } else {
        state.isSwapDealersMandatory = false
      }
    }
  }
}
