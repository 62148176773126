/* eslint-disable no-useless-catch */
import Vue from 'vue'
import Snackbar from '@/components/Snackbar'
import moment from 'moment'
import axios from 'axios'
import { DECLINED_REASON_TYPES, HTTP_ERROR_MESSAGES } from '../../constant/data'

const getDefaultState = () => {
  return {
    pmDashboardData: [],
    shouldOpenPopup: false,
    shouldRewokPopupOpen: false,
    shouldStagingPopupOpen: false,
    declinedReasons: [],
    caDeclinedReasons: [],
    isLoading: false,
    isDialogInUpdate: false,
    teamMemberList: [],
    teamList: [],
    isEntityDeclineReasonsLoading: false,
    limit: 10,
    offset: 0,
    totalRecords: 0,
    filterOptions: null,
    searchQuery: '',
    teamsFilter: [],
    assigneesFilter: [],
    workTypesFilter: [],
    filteredColumnList: [],
    isFileDownloading: false
  }
}

function toTitleCase(str) {
  if (!str) return ''
  return str.replace(/\w\S*/g, (word) => {
    return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
  })
}

export default {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    async getpmDashboardData({ commit, state }, payload) {
      // Cancel previous request
      if (state.cancelToken) {
        state.cancelToken.cancel(HTTP_ERROR_MESSAGES.REQUEST_CANCELLED)
      }
      // Create new cancel token
      const newCancelToken = axios.CancelToken.source()
      commit('SET_CANCEL_TOKEN', newCancelToken)
      try {
        commit('SET_LOADER', true)
        const requestPayload = {
          teams: state.teamsFilter || [],
          assignees: state.assigneesFilter || [],
          searchQuery: state.searchQuery || '',
          worktypes: state.workTypesFilter || [],
          limit: state.limit || 10,
          offset: state.offset || 0,
          filterOptions: state.filterOptions || null
        }

        const response = await Vue.prototype.$http2.post(
          '/dmp/pm-dashboard/',
          requestPayload,
          {
            cancelToken: newCancelToken.token
          }
        )

        if (
          response.data &&
          response.data.status === 200 &&
          response.data.data
        ) {
          commit('SET_PM_DASHBOARD_DATA', response.data.data)
        } else if (response.data && response.data.status === 404) {
          commit('SET_PM_DASHBOARD_DATA', [])
        }

        // Common Pagination Set Logic
        const paginationData = {
          limit: response.data.limit || 10,
          offset: response.data.offset || 0,
          totalRows: parseInt(response.data.totalRows) || 0
        }
        commit('SET_PM_PAGINATION_DATA', paginationData)
        commit('SET_LOADER', false)
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message)
        } else {
          throw error
        }
      }
    },
    async downloadPmDashboardData({ commit, state }) {
      try {
        commit('SET_FILE_DOWNLOADING_FLAG', true)
        const requestPayload = {
          teams: state.teamsFilter || [],
          assignees: state.assigneesFilter || [],
          searchQuery: state.searchQuery || '',
          worktypes: state.workTypesFilter || [],
          filterOptions: state.filterOptions || null
        }
        const response = await Vue.prototype.$http.post(
          '/dmp/pm-dashboard/download-csv',
          requestPayload,
          {
            responseType: 'blob' // Ensure the response is treated as a blob
          }
        )
        const blob = new Blob([response.data], { type: 'text/csv' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `pm-dashboard-data_${moment().format(
          'DD-MM-YYYY_HH:mm'
        )}.csv`
        link.click()
        Snackbar({
          message: 'File downloaded successfully',
          type: 'is-success'
        })
      } catch (error) {
        console.error('Error downloading CSV:', error)
        Snackbar({ message: 'Failed to download CSV.', type: 'is-danger' })
      } finally {
        commit('SET_FILE_DOWNLOADING_FLAG', false)
      }
    },
    /**
     * Fetches declined reasons based on provided reason types.
     *
     * - Defaults to 'ER' if no reason types are provided.
     *
     * @param {Object} context - Vuex context to commit mutations.
     * @param {Array<String>} [reasonTypes=[DECLINED_REASON_TYPES.ER]] - Reason types to filter declined reasons by.
     *
     * @throws {Error} If the HTTP request fails.
     */
    async getDeclinedReasons(
      { commit },
      reasonTypes = [DECLINED_REASON_TYPES.ER]
    ) {
      try {
        commit('SET_LOADER', true)
        commit('SET_ENTITY_DECLINE_REASONS_LOADING', true)
        const reasonTypesParam = reasonTypes.join(',')
        const response = await Vue.prototype.$http.get(
          '/dmp/pm-dashboard/declined-reasons',
          {
            params: { reasonTypes: reasonTypesParam }
          }
        )
        if (
          response.data &&
          response.data.status === 200 &&
          response.data.data
        ) {
          commit('SET_DECLINED_REASONS_DATA', {
            declinedReasons: response.data.data,
            reasonTypes
          })
        }
      } catch (error) {
        throw error
      } finally {
        commit('SET_LOADER', false)
        commit('SET_ENTITY_DECLINE_REASONS_LOADING', false)
      }
    },

    async updateSubscription({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADER', true)
        commit('SET_DIALOG_LOADER', true)
        const response = await Vue.prototype.$http.put(
          'dmp/pm-dashboard/update-subscription',
          payload
        )
        if (
          response.data &&
          response.data.status === 200 &&
          response.data.data
        ) {
          return response.data.status
        }
        return 204
      } catch (error) {
        throw error
      } finally {
        commit('SET_LOADER', false)
        commit('SET_DIALOG_LOADER', false)
      }
    },

    async refreshMaterializedView({ commit, dispatch }) {
      commit('SET_LOADER', true)
      try {
        const response = await Vue.prototype.$http.get(
          '/dmp/common/refresh-materialized-view'
        )
        return response
      } catch (error) {
        throw error
      } finally {
        commit('SET_LOADER', false)
      }
    },
    async addNewEntityFromPMView({ commit, dispatch }, payload) {
      commit('SET_LOADER', true)
      try {
        const response = await Vue.prototype.$http.post(
          '/dmp/common/add-entity',
          payload
        )
        if (
          response.data &&
          response.data.status === 200 &&
          response.data.data
        ) {
          return response.data.status
        }
        return 204
      } catch (error) {
        throw error
      } finally {
        commit('SET_LOADER', false)
      }
    },

    async getHierarchyDetails({ commit, dispatch }, requestPayload) {
      commit('SET_LOADER', true)
      try {
        const { sgaIds, isCorporateAction } = requestPayload
        const payload = { sgaIds, isCorporateAction }
        const response = await Vue.prototype.$http.post(
          `/dmp/common/hierarchy-details`,
          payload
        )
        if (
          response.data &&
          response.data.status === 200 &&
          response.data.data
        ) {
          return response.data.data
        }
        return 204
      } catch (error) {
        throw error
      } finally {
        commit('SET_LOADER', false)
      }
    },

    getTeamMemberList({ commit }, userId) {
      Vue.prototype.$http
        .get(`/dmp/dashboard/team-member?userId=${userId}`)
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              commit('TEAM_MEMBER_LIST', response.data.data)
            }
          }
        })
    },

    getTeamList({ commit }, userId) {
      Vue.prototype.$http
        .get(`/dmp/dashboard/team?userId=${userId}`)
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              commit('TEAM_LIST', response.data.data)
            }
          }
        })
    },
    async sendEntitiesBackToPool({ commit, dispatch }, payload) {
      commit('SET_LOADER', true)
      try {
        const response = await Vue.prototype.$http.post(
          '/dmp/pm-dashboard/back-to-pool',
          payload
        )
        if (response.data && response.data.status === 200) {
          return response.data.status
        }
        return 204
      } catch (error) {
        throw error
      } finally {
        commit('SET_LOADER', false)
      }
    },
    async assignEntities({ commit, dispatch }, payload) {
      commit('SET_LOADER', true)
      try {
        const response = await Vue.prototype.$http.post(
          '/dmp/pm-dashboard/assign-entities',
          payload
        )
        if (response.data && response.data.status === 200) {
          Snackbar({
            message: 'Selected items were assigned successfully.',
            type: 'is-success'
          })
          return response.data.status
        }
        return 204
      } catch (error) {
        Snackbar({
          message: 'Failed to assign selected items. Please try again',
          type: 'is-danger'
        })
        throw error
      } finally {
        commit('SET_LOADER', false)
      }
    },
    async triggerBulkAssignment({ commit, dispatch }, payload) {
      // check for environment variables
      if (
        !process.env.VUE_APP_BULK_ASSIGNMENT_API_URL ||
        !process.env.VUE_APP_BULK_ASSIGNMENT_API_KEY
      ) {
        return -1
      }
      commit('SET_LOADER', true)
      try {
        const url = process.env.VUE_APP_BULK_ASSIGNMENT_API_URL
        const response = await Vue.prototype.$http.post(url, {
          headers: {
            'x-api-key': process.env.VUE_APP_BULK_ASSIGNMENT_API_KEY
          }
        })
        if (response.data && response.data.status === 200) {
          return response.data.status
        }
        return 204
      } catch (error) {
        return 500
      } finally {
        commit('SET_LOADER', false)
      }
    },
    async updateCorporateAction({ commit }, corporateActionEntities) {
      try {
        const response = await Vue.prototype.$http.put(
          '/dmp/pm-dashboard/update-corporate-action',
          corporateActionEntities
        )
        if (response.data && response.data.status === 200) {
          Snackbar({ message: 'Successfully updated.', type: 'is-success' })
          return true
        }
      } catch (error) {
        Snackbar({ message: 'Not Updating.Try Again.', type: 'is-danger' })
        throw error
      }
    },

    submitPopupStatus({ commit }, status) {
      commit('SET_STAGING_POPUP_STATUS', status)
    },
    reworkPopupStatus({ commit }, status) {
      commit('SET_REWORK_POPUP_STATUS', status)
    },
    stagingPopupStatus({ commit }, status) {
      commit('SET_STAGINGCHANGE_POPUP_STATUS', status)
    },
    setLimitAndOffset({ commit }, { limit = 10, offset = 0 } = {}) {
      commit('SET_LIMIT_AND_OFFSET', { limit, offset })
    },
    resetPaginationData({ commit }) {
      commit('SET_LIMIT_AND_OFFSET', { limit: 10, offset: 0 })
    },

    updateCustomFilter({ commit }, filterOptions) {
      commit('SET_FILTER_OPTIONS', filterOptions)
    },
    updateWorkTypeFilter({ commit }, worktypes) {
      commit('SET_WORKTYPE_FILTER', worktypes)
    },
    updateTeamsFilter({ commit }, teamsFilter) {
      commit('SET_TEAMS_FILTER', teamsFilter)
    },
    updateAssigneesFilter({ commit }, assigneesFilter) {
      commit('SET_ASSIGNEES_FILTER', assigneesFilter)
    },
    updateSearchQuery({ commit }, searchQuery) {
      commit('SET_SEARCH_QUERY', searchQuery)
    },

    addFilteredColumnList({ commit, state }, columnObj) {
      const { columnName, isFilter } = columnObj
      let columnList = [...state.filteredColumnList]
      if (isFilter) {
        columnList = [...columnList, columnName]
      } else {
        const index = columnList.indexOf(columnName)
        if (index > -1) {
          columnList.splice(index, 1)
        }
      }
      columnList = [...new Set(columnList)]
      commit('SET_FILTERED_COLUMN_LIST', columnList)
    },
    updateFilteredColumnList({ commit }, filteredColumnList) {
      commit('SET_FILTERED_COLUMN_LIST', filteredColumnList)
    },

    setDashboardLoader({ commit }, isLoading) {
      commit('SET_LOADER', isLoading)
    },

    async uploadFile({ commit }, formData) {
      try {
        commit('SET_LOADER', true)

        const response = await Vue.prototype.$http.post(
          '/dmp/pm-dashboard/process-file',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            responseType: 'blob' // Expect a blob since the response might be a file
          }
        )

        const contentType = response.headers['content-type']

        if (contentType.includes('application/json')) {
          // Handle JSON response (successful upload)
          const isStatusOk = await new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = () => {
              try {
                const jsonResponse = JSON.parse(reader.result)
                if (jsonResponse.status === 200) {
                  Snackbar({
                    message: 'File uploaded successfully',
                    type: 'is-success'
                  })
                  resolve(true)
                } else {
                  resolve(false)
                }
              } catch (error) {
                reject(error)
              }
            }
            reader.onerror = (error) => {
              reject(error)
            }
            // Important: Read the response data as text
            reader.readAsText(response.data)
          })
          return isStatusOk
        } else if (
          contentType.includes(
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          ) ||
          contentType.includes('application/vnd.ms-excel')
        ) {
          // Handle file response (validation errors)
          const fileName = `validation_errors_${moment().format(
            'DD-MM-YYYY_HH:mm'
          )}.xlsx`
          const blob = new Blob([response.data], { type: contentType })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = fileName
          link.click()
          // to be enabled later, that's why not removing the code
          // Snackbar({
          //   message: 'Validation errors encountered. Downloading error file.',
          //   type: 'is-danger'
          // })
          return false // We don't want to close the dialog when we receive a validation error file
        } else {
          Snackbar({
            message: 'Unknown response type received',
            type: 'is-danger'
          })
          return false
        }
      } catch (error) {
        return false
      } finally {
        commit('SET_LOADER', false) // Ensure loader is set to false no matter what
      }
    }
  },
  mutations: {
    SET_DECLINED_REASONS_DATA(state, { declinedReasons, reasonTypes }) {
      const transformReasons = (reasons) => {
        return reasons.map((reasonsObj) => {
          // Assign new key
          reasonsObj.value = reasonsObj.id
          reasonsObj.text = `${reasonsObj.code}-${reasonsObj.name}`

          // Delete old keys
          delete reasonsObj.id
          delete reasonsObj.name
          delete reasonsObj.code

          return reasonsObj
        })
      }

      if (reasonTypes && reasonTypes.includes('CA')) {
        state.caDeclinedReasons = transformReasons(declinedReasons)
      } else {
        state.declinedReasons = transformReasons(declinedReasons)
      }
    },
    SET_CANCEL_TOKEN(state, cancelToken) {
      state.cancelToken = cancelToken
    },
    CLEAR_CANCEL_TOKEN(state) {
      state.cancelToken = null
    },

    SET_STAGING_POPUP_STATUS(state, status) {
      state.shouldOpenPopup = status
    },
    SET_REWORK_POPUP_STATUS(state, status) {
      state.shouldRewokPopupOpen = status
    },
    SET_STAGINGCHANGE_POPUP_STATUS(state, status) {
      state.shouldStagingPopupOpen = status
    },
    SET_PM_DASHBOARD_DATA(state, pmDashboardData) {
      const fieldsToTransform = [
        'legalEntityName',
        'status',
        'priority',
        'assignedTo'
      ]
      state.pmDashboardData = pmDashboardData.map((data) => {
        // Apply title case transformation to certain values
        fieldsToTransform.forEach((field) => {
          if (data[field]) {
            data[field] = toTitleCase(data[field])
          }
        })
        data.selected = false
        data.viewEntity = 'View'
        return data
      })
    },
    SET_PM_PAGINATION_DATA(state, paginationData) {
      state.limit = paginationData.limit
      state.offset = paginationData.offset
      state.totalRecords = paginationData.totalRows
    },
    SET_LIMIT_AND_OFFSET(state, { limit = 10, offset = 0 } = {}) {
      state.limit = limit
      state.offset = offset
    },
    SET_LOADER(state, isLoading) {
      state.isLoading = isLoading
    },
    SET_DIALOG_LOADER(state, isLoading) {
      state.isDialogInUpdate = isLoading
    },
    SET_ENTITY_DECLINE_REASONS_LOADING(state, isLoading) {
      state.isEntityDeclineReasonsLoading = isLoading
    },
    TEAM_MEMBER_LIST(state, teamMemberList) {
      // Modify each team member in the list
      const modifiedTeamMemberList = teamMemberList.map((member) => ({
        userId: member.userId,
        name: `${member.firstName} ${member.lastName}`,
        WorkType: member.WorkType
      }))

      state.teamMemberList = modifiedTeamMemberList
    },
    SET_WORKTYPE_FILTER(state, workTypes) {
      state.workTypesFilter = workTypes
    },
    TEAM_LIST(state, teamList) {
      const modifiedTeamList = teamList.map((member) => ({
        id: member.team_id,
        name: member.name,
        userIds: member.user_ids
      }))
      state.teamList = modifiedTeamList
    },

    SET_FILTER_OPTIONS(state, filterOptions) {
      state.filterOptions = filterOptions
    },

    SET_SEARCH_QUERY(state, searchQuery) {
      state.searchQuery = searchQuery
    },

    SET_TEAMS_FILTER(state, teamsFilter) {
      state.teamsFilter = teamsFilter
    },

    SET_ASSIGNEES_FILTER(state, assigneesFilter) {
      state.assigneesFilter = assigneesFilter
    },

    SET_FILTERED_COLUMN_LIST(state, filteredColumnList) {
      state.filteredColumnList = filteredColumnList
    },

    SET_FILE_DOWNLOADING_FLAG(state, isFileDownloading) {
      state.isFileDownloading = isFileDownloading
    }
  }
}
