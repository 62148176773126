import Vue from 'vue'
import { PM_ROLE } from '../../constant/data'

const getDefaultState = () => {
  return {
    activeUsersList: [],
    rolesList: [],
    isLoading: false,
    userAssociations: [],
    userList: [],
    pmUserList: [],
    teamList: []
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    async fetchActiveUsers({ commit, state }) {
      commit('SET_LOADER', true)
      try {
        const response = await Vue.prototype.$http.get(
          '/dmp/user-management/users'
        )
        if (response && response.data && response.data.status === 200) {
          if (response.data.data) {
            commit('SET_ACTIVE_USERS', response.data.data)
          }
        }
      } catch (error) {
        throw error
      } finally {
        commit('SET_LOADER', false)
      }
    },

    async deleteUserAssociation({ commit }, userAssociationId) {
      commit('SET_LOADER', true)
      try {
        const response = await Vue.prototype.$http.delete(
          `/dmp/user-management/${userAssociationId}`
        )
      } catch (error) {
        console.error('Error deleting user association:', error)
        throw error
      } finally {
        commit('SET_LOADER', false)
      }
    },

    async fetchRoles({ commit, state }) {
      commit('SET_LOADER', true)
      try {
        const response = await Vue.prototype.$http.get(
          '/dmp/user-management/roles'
        )
        if (response && response.data && response.data.status === 200) {
          if (response.data.data) {
            commit('SET_ROLES', response.data.data)
          }
        }
      } catch (error) {
        throw error
      } finally {
        commit('SET_LOADER', false)
      }
    },
    async updateUserDetails({ commit, state }, payload) {
      commit('SET_LOADER', true)
      try {
        const response = await Vue.prototype.$http.put(
          '/dmp/user-management/user',
          payload
        )
        if (response && response.data && response.data.status === 200) {
          if (response.data.data) {
            return true
          }
        }
        return false
      } catch (error) {
        throw error
      } finally {
        commit('SET_LOADER', false)
      }
    },
    async getUserAssociations({ commit }) {
      try {
        const response = await Vue.prototype.$http.get(
          '/dmp/user-management/user-associations'
        )
        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            commit('SET_USER_ASSOCIATIONS', response.data.data)
          }
        }
        if (response.data.status === 204) {
          commit('SET_USER_ASSOCIATIONS', [])
        }
      } catch (err) {
        throw new Error(err)
      }
    },

    async updateUserAssociations({ dispatch, commit }, userAssociationDetail) {
      try {
        const response = await Vue.prototype.$http.put(
          '/dmp/user-management/user-associations',
          userAssociationDetail
        )
        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            // dispatch('getUserAssociations')
          }
        }
      } catch (err) {
        throw new Error(err)
      }
    },

    async addUserAssociations({ dispatch, commit }, userAssociationDetail) {
      try {
        const response = await Vue.prototype.$http.post(
          '/dmp/user-management/user-associations',
          userAssociationDetail
        )
        if (response.data.status === 200) {
          if (response.data && response.data.data) {
            // dispatch('getUserAssociations')
          }
        }
      } catch (err) {
        throw new Error(err)
      }
    },
    getUserList({ commit }, userId) {
      Vue.prototype.$http
        .get('/dmp/user-management/user-list')
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              commit('SET_USER_LIST', response.data.data)
              commit('SET_PM_USER_LIST', response.data.data)
            }
          }
        })
    },
    getTeamList({ commit }, userId) {
      Vue.prototype.$http
        .get(`/dmp/dashboard/team?userId=${userId}`)
        .then((response) => {
          if (response.data.status === 200) {
            if (response.data && response.data.data) {
              commit('TEAM_LIST', response.data.data)
            }
          }
        })
    }
  },
  mutations: {
    SET_LOADER(state, isLoading) {
      state.isLoading = isLoading
    },
    SET_ACTIVE_USERS(state, activeUsersList) {
      state.activeUsersList = activeUsersList
    },
    SET_ROLES(state, roles) {
      const transformedRoles = (rolesList) => {
        return rolesList.map((role) => {
          // Assign new key
          role.value = String(role.roleId)
          role.text = role.roleName

          // Delete old key
          delete role.roleId
          delete role.roleName

          return role
        })
      }
      state.rolesList = transformedRoles(roles)
    },
    SET_USER_ASSOCIATIONS(state, userAssociations) {
      state.userAssociations = userAssociations.map((item, index) => {
        return { localId: index + 1, ...item }
      })
    },

    SET_USER_LIST(state, userList) {
      const modifiedUserList = userList.map((user) => user.email)
      state.userList = modifiedUserList
    },

    SET_PM_USER_LIST(state, userList) {
      const modifiedUserList = userList
        .filter((user) => user.role === PM_ROLE.ID)
        .map((user) => user.email)
      state.pmUserList = modifiedUserList
    },

    TEAM_LIST(state, teamList) {
      const modifiedTeamList = teamList.map((member) => member.name)
      state.teamList = modifiedTeamList
    }
  },
  getters: {
    getActiveUsersList: (state) => state.activeUsersList,
    getRoles: (state) => state.roles
  }
}
